import { LOGIN, LOGOUT } from 'store/actions/types';

let initialState = {
  username: sessionStorage.getItem('username'),
  isAuthenticated: sessionStorage.getItem('username') ? true : false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        username: action.payload.username,
        isAuthenticated: true,
      };
    case LOGOUT:
      return {
        ...state,
        username: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default userReducer;
