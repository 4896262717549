export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const SET_LOADING = 'SET_LOADING';
export const UNSET_LOADING = 'UNSET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const UNSET_ERROR = 'UNSET_ERROR';
export const SET_SECTION = 'SET_SECTION';
export const GET_SECTIONS = 'GET_SECTIONS';
export const SET_NEW_ENTRY = 'SET_NEW_ENTRY';
export const CANCEL_NEW_ENTRY = 'CANCEL_NEW_ENTRY';
export const SET_ASSIGN_ENTRY = 'SET_ASSIGN_ENTRY';
export const CANCEL_ASSIGN_ENTRY = 'CANCEL_ASSIGN_ENTRY';
export const SET_MESSAGES = 'SET_MESSAGES';
export const UNSET_MESSAGES = 'UNSET_MESSAGES';
export const TOGGLE_MOBILE = 'TOGGLE_MOBILE';

export const GET_DATA = 'GET_DATA';
export const GET_POSITIONS = 'GET_POSITIONS';
export const GET_EVENTS = 'GET_EVENTS';
export const GET_INSTITUTIONS = 'GET_INSTITUTIONS';
export const GET_SUPERVISIONS = 'GET_SUPERVISIONS';
export const GET_ASSIGN_ENTRY = 'GET_ASSIGN_ENTRY';
