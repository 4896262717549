import { createSelector } from 'reselect';

const selectAppState = (state) => state.app;

const selectErrorStatus = createSelector(
  [selectAppState],
  (app) => app.error.status,
);

const selectErrorMsg = createSelector([selectAppState], (app) => app.error.msg);

const selectSections = createSelector([selectAppState], (app) => app.sections);

const selectYear = createSelector([selectAppState], (app) => app.year);

const selectCurrentSection = createSelector(
  [selectAppState],
  (app) => app.current_section,
);

const selectCurrentSectionName = createSelector(
  [selectAppState],
  (app) => app.current_section.name,
);

const selectCurrentSectionEndpoint = createSelector(
  [selectAppState],
  (app) => app.current_section.endpoint,
);

const selectLoading = createSelector([selectAppState], (app) => app.loading);

const selectAssignEntryFlag = createSelector(
  [selectAppState],
  (app) => app.assign_entry_flag,
);

const selectNewEntryFlag = createSelector(
  [selectAppState],
  (app) => app.new_entry_flag,
);

const selectMobileFlag = createSelector(
  [selectAppState],
  (app) => app.mobile_flag,
);

const selectMessagesFlag = createSelector(
  [selectAppState],
  (app) => app.messages_flag,
);

export {
  selectAssignEntryFlag,
  selectCurrentSection,
  selectCurrentSectionEndpoint,
  selectCurrentSectionName,
  selectErrorMsg,
  selectErrorStatus,
  selectLoading,
  selectMessagesFlag,
  selectMobileFlag,
  selectNewEntryFlag,
  selectSections,
  selectYear,
};
