import { Autocomplete, TextField } from '@mui/material';

const AutocompleteField = ({
  field,
  label,
  options,
  formatOption,
  onChange,
  getOptionLabel = (option) => option.name,
  isRequired = false,
  autoFocus = false,
  openOnFocus = false,
  value = undefined,
}) => (
  <Autocomplete
    id={`${field}-autocomplete`}
    value={value}
    disablePortal
    fullWidth
    openOnFocus={openOnFocus}
    renderInput={(params) => (
      <TextField
        {...params}
        margin="dense"
        label={label}
        required={isRequired}
        autoFocus={autoFocus}
      />
    )}
    options={options}
    getOptionLabel={getOptionLabel}
    renderOption={(props, option) => (
      <li {...props} key={option.id || option.position_id}>
        {formatOption(option)}
      </li>
    )}
    onChange={onChange}
  />
);

export default AutocompleteField;
