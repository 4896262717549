import { API_SERVER_URL } from 'config';
import {
  createRequestUrl,
  defaultOptions,
  isPayloadEmpty,
  sendRequest,
  transformPayload,
} from 'services/utils';

const getData = async (endpoint) => {
  const options = defaultOptions('GET');
  const request = new Request(
    createRequestUrl(API_SERVER_URL, endpoint),
    options,
  );

  return await sendRequest(request);
};

const createData = async (endpoint, payload = {}) => {
  const options = defaultOptions('POST');
  options.body = transformPayload(payload);
  const request = new Request(
    createRequestUrl(API_SERVER_URL, endpoint),
    options,
  );

  return await sendRequest(request);
};

const deleteData = async (endpoint, payload = {}) => {
  const options = defaultOptions('DELETE');
  if (!isPayloadEmpty(payload)) {
    options.body = transformPayload(payload);
  }
  const request = new Request(
    createRequestUrl(API_SERVER_URL, endpoint),
    options,
  );

  return await sendRequest(request);
};

const modifyData = async (endpoint, payload = {}) => {
  const options = defaultOptions('PUT');
  options.body = transformPayload(payload);
  const request = new Request(
    createRequestUrl(API_SERVER_URL, endpoint),
    options,
  );

  return await sendRequest(request);
};

export { createData, deleteData, getData, modifyData };
