export const DATE_FIELD = 'date';
export const START_DATE_FIELD = 'start_date';
export const END_DATE_FIELD = 'end_date';
export const ADMISSION_DATE_FIELD = 'admission_date';
export const DEPOSIT_DATE_FIELD = 'deposit_deadline';
export const EXTENSION_DATE_FIELD = 'extension_date';
export const MONTH_FIELD = 'month';
export const YEAR_FIELD = 'year';
export const POSITION_ID_FIELD = 'position_id';
export const TYPE_FIELD = 'type';
export const ROLE_FIELD = 'role';
export const LEVEL_FIELD = 'level';
export const STATUS_FIELD = 'status';
export const EVENT_ID_FIELD = 'event_id';
export const INSTITUTION_ID_FIELD = 'institution_id';
export const SUPERVISOR_ID_FIELD = 'supervisor_id';
export const STUDENT_ID_FIELD = 'student_id';
export const WORKLOAD_FIELD = 'workload';

export const DATE_FIELDS = [
  DATE_FIELD,
  START_DATE_FIELD,
  END_DATE_FIELD,
  ADMISSION_DATE_FIELD,
  DEPOSIT_DATE_FIELD,
  EXTENSION_DATE_FIELD,
];
export const TYPE_FIELDS = [TYPE_FIELD, LEVEL_FIELD, STATUS_FIELD];

export const RESEARCH_INTERN = 'Research Intern';
export const PHD_STUDENT = 'Phd Student';
export const POSTDOC_RESEARCHER = 'Post-doctoral Researcher';

export const TEACHER = 'Teacher';
export const STUDENT = 'Student';

export const ACCEPTED_PAPER = 'Accepted';

export const NONDISPLAY_FIELDS = [
  MONTH_FIELD,
  // YEAR_FIELD,
  POSITION_ID_FIELD,
  EVENT_ID_FIELD,
  INSTITUTION_ID_FIELD,
  SUPERVISOR_ID_FIELD,
  STUDENT_ID_FIELD,
];

export const EXCLUDED_FIELDS = [POSITION_ID_FIELD, YEAR_FIELD];
export const STUDENT_ROLES = [RESEARCH_INTERN, PHD_STUDENT];
export const COURSE_ROLES = [TEACHER, STUDENT];
