import {
  EVENTS,
  EVENT_SECTIONS,
  INSTITUTIONS,
  INSTITUTION_SECTIONS,
  SUPERVISIONS,
} from 'constants/endpoints';

import { GET_EVENTS, GET_INSTITUTIONS, GET_SUPERVISIONS } from './types';

const genericAction = (type, payload = null) => {
  return {
    type: type,
    payload: payload,
  };
};

const getStaticQueryArgs = (endpoint) => {
  switch (endpoint) {
    case EVENTS:
      return {
        path: '/events/add',
        refresh_path: '/events/get',
        action: GET_EVENTS,
      };
    case INSTITUTIONS:
      return {
        path: '/institutions/add',
        refresh_path: '/institutions/get',
        action: GET_INSTITUTIONS,
      };
    case SUPERVISIONS:
      return {
        path: '/positions/addStudent',
        refresh_path: '/supervisions/getStudents',
        action: GET_SUPERVISIONS,
      };
    default:
      return {
        path: null,
        refresh_path: null,
        action: null,
      };
  }
};

const getAddEntryArgs = (endpoint) => {
  if (EVENT_SECTIONS.includes(endpoint)) {
    return {
      path: '/events/get',
      action: GET_EVENTS,
    };
  } else if (INSTITUTION_SECTIONS.includes(endpoint)) {
    return {
      path: '/institutions/get',
      action: GET_INSTITUTIONS,
    };
  } else if (endpoint === SUPERVISIONS) {
    return {
      path: '/supervisions/getStudents',
      action: GET_SUPERVISIONS,
    };
  } else {
    return {
      path: null,
      action: null,
    };
  }
};

export { genericAction, getAddEntryArgs, getStaticQueryArgs };
