export const USER_LOGGED_IN = '/internal/userLoggedIn';
export const LOGIN = '/auth/login';
export const LOGOUT = '/auth/logout';

export const AWARDS = '/awards';
export const CONFERENCE_SERVICES = '/conferenceServices';
export const COURSES = '/courses';
export const EVENTS = '/events';
export const INDUSTRY_COLLABS = '/industryCollabs';
export const INSTITUTIONS = '/institutions';
export const MAIN = '/';
export const OTHER_SERVICES = '/otherServices';
export const OTHER_TALKS = '/otherTalks';
export const POSITIONS = '/positions';
export const PROTOTYPES = '/prototypes';
export const SUPERVISIONS = '/supervisions';
export const TALKS = '/talks';
export const VISITS = '/visits';
export const PAPERS = '/papers';
export const ACADEMIC = '/academicInfo';

export const ASSIGN_SECTIONS = [COURSES, AWARDS, PROTOTYPES];
export const EVENT_SECTIONS = [AWARDS, CONFERENCE_SERVICES, TALKS, PAPERS];
export const INSTITUTION_SECTIONS = [
  COURSES,
  INDUSTRY_COLLABS,
  OTHER_TALKS,
  VISITS,
];
