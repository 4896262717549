import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

const DateField = ({ field, onChange, isRequired = false, value = null }) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      id={field}
      value={value ? dayjs(value) : null}
      label={`${field[0].toUpperCase()}${field.slice(1)}${isRequired ? ' *' : ''}`}
      views={['year', 'month', 'day']}
      required={isRequired}
      format="YYYY-MM-DD"
      onChange={(newValue) => {
        const parsedDate = dayjs(newValue).format('YYYY-MM-DD');
        onChange(parsedDate === 'Invalid Date' ? null : parsedDate);
      }}
      sx={{ width: '100%' }}
    />
  </LocalizationProvider>
);

export default DateField;
