import { combineReducers } from 'redux';

import appReducer from './appReducer';
import dataReducer from './dataReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
  app: appReducer,
  data: dataReducer,
  user: userReducer,
});

export default rootReducer;
