import {
  ACADEMIC,
  AWARDS,
  CONFERENCE_SERVICES,
  COURSES,
  INDUSTRY_COLLABS,
  OTHER_SERVICES,
  OTHER_TALKS,
  PAPERS,
  PROTOTYPES,
  SUPERVISIONS,
  TALKS,
  VISITS,
} from 'constants/endpoints';

export const APP_TITLE = 'Annual Report';

export const AUTH_ERROR_MSG = ['Sorry. This user does not exist.'];
export const ADD_MSG = 'add';
export const ALERT_MSG = 'alert';
export const DEL_MSG = 'delete';
export const MESSAGES = {
  [AWARDS]: [
    {
      type: ALERT_MSG,
      msg: 'Please, introduce the awards granted to you during this year',
    },
    {
      type: ADD_MSG,
      msg: 'Press in this icon to assign to an already registered award. There you will find further instructions in case you want to register a new one',
    },
    {
      type: ALERT_MSG,
      msg: 'When creating a new award, all fields are mandatory. Select the event among the list of conferences, journal, workshops and other. Introduce the name of the award, and select the type of award. If the award is not associated with any conference or journal, select the option none for event.',
    },
    {
      type: DEL_MSG,
      msg: 'You can delete existing entries by pressing this button.',
    },
  ],
  [INDUSTRY_COLLABS]: [
    {
      type: ALERT_MSG,
      msg: 'Please, introduce the companies or institutions that you collaborated with this year',
    },
    {
      type: ADD_MSG,
      msg: 'Press in this icon to add a new entry. Select the name of the institution from the dropdown list. If it is not there, register the institution following the instructions in General Guidelines. Provide a brief description of the sector of the institution in type and a brief description of the nature of the collaboration. Finally, add a contact name in the company/institution. ',
    },
    {
      type: DEL_MSG,
      msg: 'You can delete existing entries by pressing this button.',
    },
  ],
  [PROTOTYPES]: [
    {
      type: ALERT_MSG,
      msg: 'Please, introduce the prototypes you developed/maintained this year',
    },
    {
      type: ADD_MSG,
      msg: 'Press in this icon to assign a prototype to you. You will find further instructions in case you want to register a new one after pressing',
    },
    {
      type: ALERT_MSG,
      msg: 'When creating a new prototype, all fields are mandatory. Provide a brief description of your prototype, its name and the url to its repository.',
    },
    {
      type: DEL_MSG,
      msg: 'You can delete existing entries by pressing this button.',
    },
  ],
  [CONFERENCE_SERVICES]: [
    {
      type: ALERT_MSG,
      msg: 'Please, introduce role and journal/conference in which you participated this year.',
    },
    {
      type: ADD_MSG,
      msg: 'Press in this icon to add a new entry. If the event does not exist, go to General Guidelines to add a new event. ',
    },
    {
      type: DEL_MSG,
      msg: 'You can delete existing entries by pressing this button.',
    },
  ],
  [OTHER_SERVICES]: [
    {
      type: ALERT_MSG,
      msg: 'Please, introduce the type of service you provided and a brief description of your tasks. In the case of Association and Organization Committees, provide the text as you want it to appear in the annual report.',
    },
    { type: ADD_MSG, msg: 'Press in this icon to add a new entry.' },
    {
      type: DEL_MSG,
      msg: 'You can delete existing entries by pressing this button.',
    },
  ],
  [SUPERVISIONS]: [
    {
      type: ALERT_MSG,
      msg: 'Please, add the students that you supervised this year. Include here those students that you supervised for a fraction of the years, those whose supervision ended during the year, and those whose supervision also continues during the next year.',
    },
    {
      type: ADD_MSG,
      msg: 'Press in this icon to add a new entry. If the you student is not in the dropdown list, go to General Guidelines to add a new student. ',
    },
    {
      type: DEL_MSG,
      msg: 'You can delete existing entries by pressing this button.',
    },
  ],
  [TALKS]: [
    {
      type: ALERT_MSG,
      msg: 'Please, introduce the talks and seminars you gave this year.',
    },
    {
      type: ADD_MSG,
      msg: 'Press in this icon to add a new entry. All fields are mandatory. Select the type of talk and the event in which it took place from the dropdown list. Provide the name of the talk, the city and country in which it was given, a url to the slides or to the talk video and the date. If the event does not exist, go to General Guidelines to add a new event.',
    },
    {
      type: DEL_MSG,
      msg: 'You can delete existing entries by pressing this button.',
    },
  ],
  [OTHER_TALKS]: [
    {
      type: ALERT_MSG,
      msg: 'Please, introduce the talks, seminars and lectures given at universities, companies and other institutions.',
    },
    {
      type: ADD_MSG,
      msg: 'Press in this icon to add a new entry. All fields are mandatory.  Provide the name of the talk, the institution in which it was given, a url to the talk video and the date. If the university, company or institution does not exist, go to General Guidelines to add a new institution.',
    },
    {
      type: DEL_MSG,
      msg: 'You can delete existing entries by pressing this button.',
    },
  ],
  [COURSES]: [
    {
      type: ALERT_MSG,
      msg: 'Please, introduce the University courses in which you participated as lecturer or student this year.',
    },
    {
      type: ADD_MSG,
      msg: 'Press in this icon to assign a course to you. You will find further instructions in case you want to register a new course.',
    },
    {
      type: ALERT_MSG,
      msg: 'When creating a new course, all fields are mandatory.',
    },
    {
      type: DEL_MSG,
      msg: 'You can delete existing entries by pressing this button.',
    },
  ],
  [VISITS]: [
    {
      type: ALERT_MSG,
      msg: 'Please, introduce the visits to external research institutions of companies during this year',
    },
    {
      type: ADD_MSG,
      msg: 'Press in this icon to register a new entry.',
    },
    {
      type: ALERT_MSG,
      msg: 'All fields are mandatory. Note that the end date must be at least one day later than the start date.',
    },
    {
      type: DEL_MSG,
      msg: 'You can delete existing entries by pressing this button.',
    },
  ],
  [PAPERS]: [
    {
      type: ALERT_MSG,
      msg: 'Please, introduce the papers accepted or in the pipeline in which you have been working this year.',
    },
    {
      type: ADD_MSG,
      msg: 'You can add a new paper pressing this button.',
    },
    {
      type: ALERT_MSG,
      msg: 'When adding a new paper, all fields, except event, are mandatory. If the paper was accepted, then event is also mandatory.',
    },
    {
      type: DEL_MSG,
      msg: 'You can delete existing entries by pressing this button.',
    },
  ],
  [ACADEMIC]: [
    {
      type: ALERT_MSG,
      msg: 'Please, verify if your academic details are aligned with the information in THESIS.',
    },
  ],
};
