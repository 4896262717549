import { createSelector } from 'reselect';

const selectUserState = (state) => state.user;

const selectUsername = createSelector(
  [selectUserState],
  (user) => user.username,
);

const selectIsAuthenticated = createSelector(
  [selectUserState],
  (user) => !!user.isAuthenticated,
);

export { selectIsAuthenticated, selectUsername };
