import { createSelector } from 'reselect';

const selectDataState = (state) => state.data;

const selectFirstPosition = createSelector(
  [selectDataState],
  (data) => data.positions[0],
);

const selectData = createSelector([selectDataState], (data) => data.data);

const selectEvents = createSelector([selectDataState], (data) => data.events);

const selectInstitutions = createSelector(
  [selectDataState],
  (data) => data.institutions,
);

const selectSupervisions = createSelector(
  [selectDataState],
  (data) => data.supervisions,
);

const selectAssignEntry = createSelector(
  [selectDataState],
  (data) => data.assign_entry,
);

export {
  selectAssignEntry,
  selectData,
  selectEvents,
  selectFirstPosition,
  selectInstitutions,
  selectSupervisions,
};
