import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, Grid } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { ADD_MSG, DEL_MSG, MESSAGES } from 'constants/messages';
import { selectCurrentSectionEndpoint } from 'store/selectors';

const Messages = () => {
  const section = useSelector(selectCurrentSectionEndpoint);

  return (
    <Grid container spacing={1} p={1}>
      {MESSAGES[section].map((entry, id) => (
        <Grid item xs={12} key={id}>
          {entry.type === ADD_MSG ? (
            <Alert severity="success" icon={<AddIcon fontSize="inherit" />}>
              {entry.msg}
            </Alert>
          ) : entry.type === DEL_MSG ? (
            <Alert severity="error" icon={<DeleteIcon fontSize="inherit" />}>
              {entry.msg}
            </Alert>
          ) : (
            <Alert severity="success">{entry.msg}</Alert>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default Messages;
