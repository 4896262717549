import { drawerWidth } from 'components/Main/Main.style';
import { baselineTheme } from 'styles';

const itemStyle = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};
const title = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
};
const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};
const titleStyle = { ...title, ...itemCategory, fontSize: 22, color: '#fff' };
const buttonStyle = {
  ...itemStyle,
  ...itemCategory,
  fontSize: 22,
  color: '#fff',
};
const boxStyle = { bgcolor: '#008080' };
const paperPropsStyle = { style: { width: drawerWidth } };
const navigatorStyle = { display: { sm: 'block', xs: 'none' } };

const listStyle = {
  maxHeight: '95vh',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: baselineTheme.palette.primary.light,
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: baselineTheme.palette.primary.dark,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: baselineTheme.palette.primary.main,
  },
};

export {
  boxStyle,
  buttonStyle,
  itemStyle,
  listStyle,
  navigatorStyle,
  paperPropsStyle,
  titleStyle,
};
