import {
  GET_ASSIGN_ENTRY,
  GET_DATA,
  GET_EVENTS,
  GET_INSTITUTIONS,
  GET_POSITIONS,
  GET_SUPERVISIONS,
} from 'store/actions/types';

let initialState = {
  data: [''],
  positions: [''],
  events: [''],
  institutions: [''],
  supervisions: [''],
  assign_entry: [{}],
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATA:
      return {
        ...state,
        data: action.payload.data,
      };
    case GET_POSITIONS:
      return {
        ...state,
        positions: action.payload.data,
      };
    case GET_EVENTS:
      return {
        ...state,
        events: action.payload.data,
      };
    case GET_INSTITUTIONS:
      return {
        ...state,
        institutions: action.payload.data,
      };
    case GET_SUPERVISIONS:
      return {
        ...state,
        supervisions: action.payload.data,
      };
    case GET_ASSIGN_ENTRY:
      return {
        ...state,
        assign_entry: action.payload.data,
      };
    default:
      return state;
  }
};

export default dataReducer;
