import { API_SERVER_URL } from 'config';
import { LOGOUT, USER_LOGGED_IN } from 'constants/endpoints';
import { createRequestUrl, defaultOptions, sendRequest } from 'services/utils';

const fetchAuthStatus = async () => {
  const options = defaultOptions('GET');
  const params = { returnUsername: 'true' };
  const request = new Request(
    createRequestUrl(API_SERVER_URL, USER_LOGGED_IN, params),
    options,
  );

  return await sendRequest(request);
};

const logout = async () => {
  const options = defaultOptions('GET');
  const request = new Request(
    createRequestUrl(API_SERVER_URL, LOGOUT),
    options,
  );

  await sendRequest(request);
};
export { fetchAuthStatus, logout };
