const avatarStyle = { margin: 1, backgroundColor: 'primary.main' };
const elementStyle = { margin: 1 };
const loginIconStyle = { marginLeft: 1 };
const loginBoxStyle = {
  marginTop: 8,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: 'white',
  border: '1px solid',
  borderColor: 'grey',
  borderRadius: 1,
  padding: 2,
};

export { avatarStyle, elementStyle, loginBoxStyle, loginIconStyle };
