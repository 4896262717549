import { ACCEPTED_PAPER } from 'constants/fields';

const validEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

const validAward = (data, events, types) =>
  data.name &&
  events.find((o) => o.id === data.event_id) &&
  types.includes(data.type);

const validIndustryCollab = (data, institutions) =>
  institutions.find((o) => o.id === data.institution_id) &&
  data.type &&
  data.contact_name;

const validPrototype = (data) => data.name;

const validConferenceService = (data, events, types) =>
  events.find((o) => o.id === data.event_id) && types.includes(data.type);

const validOtherService = (data, types) =>
  data.description && types.includes(data.type);

const validSupervision = (data, students) =>
  students.find((o) => o.position_id === data.student_id);

const validOtherTalk = (data, institutions) =>
  institutions.find((o) => o.id === data.institution_id) &&
  data.title &&
  data.date;

const validTalk = (data, events, types) =>
  types.includes(data.type) &&
  events.find((o) => o.id === data.event_id) &&
  data.title &&
  data.city &&
  data.country &&
  data.program_url &&
  data.date;

const validCourse = (data, institutions, level_types, role_types) =>
  institutions.find((o) => o.id === data.institution_id) &&
  data.name &&
  level_types.includes(data.level) &&
  data.workload &&
  data.program &&
  role_types.includes(data.role);

const validVisit = (data, institutions) =>
  institutions.find((o) => o.id === data.institution_id) &&
  data.supervisors &&
  data.start_date &&
  data.end_date &&
  data.end_date > data.start_date;

const validPaper = (data, events, types) =>
  types.includes(data.status) &&
  events.find((o) =>
    data.status === ACCEPTED_PAPER ? o.id === data.event_id : true,
  ) &&
  data.title &&
  data.authors;

const validAcademic = (data) => data.advisors;

export {
  validAcademic,
  validAward,
  validConferenceService,
  validCourse,
  validEmail,
  validIndustryCollab,
  validOtherService,
  validOtherTalk,
  validPaper,
  validPrototype,
  validSupervision,
  validTalk,
  validVisit,
};
