import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateAuthStatus } from 'store/actions';
import { selectIsAuthenticated } from 'store/selectors';

const useAuth = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  React.useEffect(() => {
    dispatch(updateAuthStatus());
  }, [dispatch]);

  return isAuthenticated;
};

export default useAuth;
