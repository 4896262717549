import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EventIcon from '@mui/icons-material/Event';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AppBar,
  Box,
  Button,
  Grid,
  Link,
  Toolbar,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { Loading } from 'components';
import { selectLoading } from 'store/selectors';

import {
  appBarStyle,
  boxStyle,
  buttonStyle,
  columnStyle,
  elemStyle,
  typoStyle,
} from './DefaultContent.style';

const DefaultContent = () => {
  const loading = useSelector(selectLoading);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <Box sx={boxStyle}>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          sx={appBarStyle}>
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Typography variant="h5">General Guidelines</Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <Typography mx={2} my={5} color="text.secondary" align="center">
          This survey tool is used to standardize the input of data to create
          the IMDEA Software Annual Report.
        </Typography>

        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header">
            <Typography>Meaning of the icons</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={columnStyle}>
              <Box sx={elemStyle}>
                <Box>
                  <Button variant="contained" sx={buttonStyle}>
                    <HomeIcon />
                  </Button>
                </Box>
                <Typography variant="subtitle1" sx={typoStyle}>
                  Return to General Guidelines page.
                </Typography>
              </Box>

              <Box sx={elemStyle}>
                <Box>
                  <Button variant="contained" sx={buttonStyle}>
                    <AddIcon />
                  </Button>
                </Box>
                <Typography variant="subtitle1" sx={typoStyle}>
                  Open dialog to register a new entry.
                </Typography>
              </Box>

              <Box sx={elemStyle}>
                <Box>
                  <Button variant="outlined" sx={buttonStyle}>
                    <PersonAddIcon />
                  </Button>
                </Box>
                <Typography variant="subtitle1" sx={typoStyle}>
                  Open dialog to register a new student.
                </Typography>
              </Box>

              <Box sx={elemStyle}>
                <Box>
                  <Button variant="outlined" sx={buttonStyle}>
                    <AccountBalanceIcon />
                  </Button>
                </Box>
                <Typography variant="subtitle1" sx={typoStyle}>
                  Open dialog to register a new institution (e.g. University,
                  Company, Research Institution).
                </Typography>
              </Box>

              <Box sx={elemStyle}>
                <Box>
                  <Button variant="outlined" sx={buttonStyle}>
                    <EventIcon />
                  </Button>
                </Box>
                <Typography variant="subtitle1" sx={typoStyle}>
                  Open dialog to register a new event (e.g. Conference,
                  Journal).
                </Typography>
              </Box>

              <Box sx={elemStyle}>
                <Box>
                  <Button
                    variant="outlined"
                    sx={buttonStyle}
                    color="error"
                    fullWidth>
                    <DeleteIcon />
                  </Button>
                </Box>
                <Typography variant="subtitle1" sx={typoStyle}>
                  Open dialog to delete an entry.
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header">
            <Typography>Research contributions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Alert severity="error">
                  Make sure your research contributions are in the shared *.bib
                  files:
                  svn+ssh://software.imdea.org/srv/svn/imdea-web/pubs/bibtex
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <Alert severity="warning">
                  Please include in those files all publications, even if to
                  appear later. Note that this is very important: papers will
                  not appear in the annual report or be otherwise counted if
                  they are not in the*.bib files. We do not want to sell
                  ourselves short!
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <Alert severity="success">
                  If you place in the file publications prior to working at the
                  Institute, please tag them with "projects" = noimdea so that
                  they are not counted as publications from the Institute"
                </Alert>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header">
            <Typography>Research project management</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Alert severity="warning">
                  Check
                  <Link
                    href="https://intranet.software.imdea.org/employees/projects-information.html"
                    underline="none"
                    target="_blank">
                    {' the intranet'}
                  </Link>{' '}
                  to see if the projects in which you are involved or managing
                  are registered.
                </Alert>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header">
            <Typography>Completed supervised thesis</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Alert severity="warning">
                  Check
                  <Link
                    href="https://intranet.software.imdea.org/faculty/mythesis"
                    underline="none"
                    target="_blank">
                    {' the intranet'}
                  </Link>{' '}
                  to see if the thesis for your supervised students are
                  included.
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <Alert severity="error">
                  If the completed thesis are not registered, register them.
                </Alert>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }
};

export default DefaultContent;
