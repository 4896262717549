import { Box } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { Loading } from 'components';
import AssignCard from 'components/Main/Content/AssignCard/AssignCard';
import DataTable from 'components/Main/Content/DataTable/DataTable';
import DefaultContent from 'components/Main/Content/DefaultContent/DefaultContent';
import Messages from 'components/Main/Content/Messages/Messages';
import NewCard from 'components/Main/Content/NewCard/NewCard';
import TopBar from 'components/Main/Content/TopBar/TopBar';
import { ACADEMIC } from 'constants/endpoints';
import {
  selectAssignEntryFlag,
  selectCurrentSection,
  selectData,
  selectLoading,
  selectMessagesFlag,
  selectNewEntryFlag,
} from 'store/selectors';

import { AcademicInfo } from './AcademicInfo';

const Content = () => {
  const loading = useSelector(selectLoading);
  const data = useSelector(selectData);
  const curr_section = useSelector(selectCurrentSection);
  const new_entry_flag = useSelector(selectNewEntryFlag);
  const assign_entry_flag = useSelector(selectAssignEntryFlag);
  const message_flag = useSelector(selectMessagesFlag);

  if (loading) {
    return <Loading />;
  }

  if (!curr_section.name) {
    return (
      <Box>
        <DefaultContent />
      </Box>
    );
  } else {
    return (
      <Box>
        <TopBar />

        {message_flag && <Messages />}

        {new_entry_flag && <NewCard />}

        {assign_entry_flag && <AssignCard />}

        {curr_section.endpoint === ACADEMIC ? (
          <AcademicInfo />
        ) : (
          data.length > 0 && <DataTable />
        )}
      </Box>
    );
  }
};

export default Content;
