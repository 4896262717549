import {
  CANCEL_ASSIGN_ENTRY,
  CANCEL_NEW_ENTRY,
  GET_SECTIONS,
  SET_ASSIGN_ENTRY,
  SET_ERROR,
  SET_LOADING,
  SET_MESSAGES,
  SET_NEW_ENTRY,
  SET_SECTION,
  TOGGLE_MOBILE,
  UNSET_ERROR,
  UNSET_LOADING,
  UNSET_MESSAGES,
} from 'store/actions/types';

let initialState = {
  sections: [''],
  current_section: {
    name: '',
    endpoint: '',
  },
  error: {
    status: false,
    msg: '',
  },
  loading: false,
  year: 2024,
  new_entry_flag: false,
  assign_entry_flag: false,
  mobile_flag: false,
  messages_flag: true,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case UNSET_LOADING:
      return {
        ...state,
        loading: false,
      };
    case SET_ERROR:
      return {
        ...state,
        error: {
          status: true,
          msg: action.payload,
        },
      };
    case UNSET_ERROR:
      return {
        ...state,
        error: {
          status: false,
          msg: '',
        },
      };
    case SET_SECTION:
      return {
        ...state,
        current_section: action.payload,
      };
    case GET_SECTIONS:
      return {
        ...state,
        sections: action.payload.data,
      };
    case SET_NEW_ENTRY:
      return {
        ...state,
        new_entry_flag: true,
      };
    case CANCEL_NEW_ENTRY:
      return {
        ...state,
        new_entry_flag: false,
      };
    case SET_ASSIGN_ENTRY:
      return {
        ...state,
        assign_entry_flag: true,
      };
    case CANCEL_ASSIGN_ENTRY:
      return {
        ...state,
        assign_entry_flag: false,
      };
    case TOGGLE_MOBILE:
      return {
        ...state,
        mobile_flag: !state.mobile_flag,
      };
    case SET_MESSAGES:
      return {
        ...state,
        messages_flag: true,
      };
    case UNSET_MESSAGES:
      return {
        ...state,
        messages_flag: false,
      };
    default:
      return state;
  }
};

export default appReducer;
