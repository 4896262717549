import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Grid,
  Tooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { renderFields } from 'components/Main/Content/NewCard/renderFields';
import { EXCLUDED_FIELDS, POSITION_ID_FIELD } from 'constants/fields';
import { createQuery, modifyQuery } from 'store/actions';
import {
  selectCurrentSection,
  selectData,
  selectEvents,
  selectFirstPosition,
  selectInstitutions,
  selectSupervisions,
  selectYear,
} from 'store/selectors';
import { validAcademic } from 'utils';

import { AddInstitution } from '../NewCard/AddEntry';
import { spanStyle } from './AcademicInfo.style';

const AcademicInfo = () => {
  const dispatch = useDispatch();

  const data = useSelector(selectData);
  const position = useSelector(selectFirstPosition);
  const year = useSelector(selectYear);
  const currsection = useSelector(selectCurrentSection);
  const events = useSelector(selectEvents);
  const institutions = useSelector(selectInstitutions);
  const students = useSelector(selectSupervisions);

  const [newData, setNewData] = React.useState({
    [POSITION_ID_FIELD]: position.id,
    admission_date: null,
    advisors: '',
    deposit_deadline: null,
    extension_date: null,
    institution_id: null,
    program: '',
    required_credits: '',
    satisfied_credits: '',
    thesis_title: '',
  });

  React.useEffect(() => {
    if (Object.keys(data).length !== 0) {
      const normalizeValue = (value) => (value !== null ? value : '');
      const normalizeDate = (date) =>
        date ? dayjs(date).format('YYYY-MM-DD') : null;
      const normalizeData = (data) => ({
        [POSITION_ID_FIELD]: position.id,
        admission_date: normalizeDate(data.admission_date),
        advisors: normalizeValue(data.advisors),
        deposit_deadline: normalizeDate(data.deposit_deadline),
        extension_date: normalizeDate(data.extension_date),
        institution_id: data.institution_id ? data.institution_id : null,
        program: normalizeValue(data.program),
        required_credits: normalizeValue(data.required_credits),
        satisfied_credits: normalizeValue(data.satisfied_credits),
        thesis_title: normalizeValue(data.thesis_title),
      });

      const normalizedData = normalizeData(data);
      setNewData(normalizedData);
    }
  }, [data, position]);

  const [formValid, setFormValid] = React.useState(validAcademic(data));

  const updateData = (value, key) => {
    const update = { ...newData, [key]: value };
    setNewData(update);
    setFormValid(validAcademic(update));
  };

  const createEntry = () => {
    const requestData = Object.fromEntries(
      Object.entries(newData).filter(
        ([_, value]) => value !== null && value !== undefined && value !== '',
      ),
    );
    if (Object.keys(data).length === 0) {
      dispatch(
        createQuery(currsection.endpoint, position.id, year, requestData),
      );
    } else {
      dispatch(
        modifyQuery(currsection.endpoint, position.id, year, requestData),
      );
    }
  };

  return (
    <Card variant="outlined" square mt={1}>
      <CardContent>
        <Grid
          container
          spacing={1}
          justifyContent="space-around"
          alignItems="center">
          {currsection.fields
            .filter((field) => !EXCLUDED_FIELDS.includes(field))
            .map((field, i) => (
              <Grid item xs={6} key={i}>
                {renderFields(
                  field,
                  currsection,
                  events,
                  students,
                  institutions,
                  updateData,
                  newData,
                  null,
                  null,
                )}
              </Grid>
            ))}
        </Grid>
      </CardContent>

      <CardActions>
        <ButtonGroup
          disableElevation
          orientation="horizontal"
          fullWidth
          m={0.5}>
          <Tooltip title="Update data">
            <span style={spanStyle}>
              <Button
                variant="outlined"
                onClick={createEntry}
                disabled={!formValid}>
                <CheckCircleIcon />
              </Button>
            </span>
          </Tooltip>

          <AddInstitution />
        </ButtonGroup>
      </CardActions>
    </Card>
  );
};

export default AcademicInfo;
