import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Grid,
  IconButton,
  ListItemButton,
  ListItemIcon,
  Toolbar,
} from '@mui/material';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { logout, toggleMobile } from 'store/actions';

import { gridItemStyle } from './Header.style';

const Header = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const toggleDrawer = () => {
    dispatch(toggleMobile());
  };

  return (
    <AppBar color="primary" position="sticky" elevation={0}>
      <Toolbar>
        <Grid container spacing={1} alignItems="center">
          <Grid item sx={gridItemStyle}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start">
              <MenuIcon />
            </IconButton>
          </Grid>

          <Grid item xs />

          <Grid item>
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              Logout
            </ListItemButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
