import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Alert,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FixedListField } from 'components/Main/Content/Fields';
import { INSTITUTIONS } from 'constants/endpoints';
import { createStaticQuery } from 'store/actions';
import { selectSections } from 'store/selectors';

const AddInstitution = () => {
  const dispatch = useDispatch();

  const sections = useSelector(selectSections);
  const institution_types =
    (sections.find((section) => section.endpoint === INSTITUTIONS) || {})
      .types || [];

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [formValid, setFormValid] = React.useState(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
    updateData(institution_types[0], 'type');
  };

  const checkForm = () => {
    if (
      formData.name &&
      formData.country &&
      institution_types.includes(formData.type)
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  const updateData = (value, key) => {
    let update = formData;
    update[key] = value;

    setFormData(update);
    checkForm();
  };

  const cancelEntry = () => {
    setDialogOpen(false);
    setFormData({});
    setFormValid(false);
  };

  const createEntry = () => {
    dispatch(createStaticQuery(INSTITUTIONS, formData));
  };

  return (
    <React.Fragment>
      <Tooltip title="Add new Institution">
        <Button variant="outlined" fullWidth onClick={handleClickOpen}>
          <AccountBalanceIcon />
        </Button>
      </Tooltip>

      <Dialog
        open={dialogOpen}
        onClose={cancelEntry}
        aria-labelledby="add-new-institution">
        <DialogTitle id="add-new-institution">Add New Institution</DialogTitle>

        <DialogContent>
          <Alert severity="warning">
            Add the institution with its english name only. For example, if you
            want to add Universidad de Leon, write Univeristy of Leon. Be sure
            to check that the institution you are adding does not already exist!
          </Alert>

          <TextField
            autoFocus
            required
            onChange={(event) => {
              updateData(event.target.value, 'name');
            }}
            margin="dense"
            id="name"
            label="Name"
            fullWidth
          />

          <TextField
            required
            onChange={(event) => {
              updateData(event.target.value, 'country');
            }}
            margin="dense"
            id="country"
            label="Country"
            fullWidth
          />

          {institution_types && (
            <FixedListField
              field="type"
              options={institution_types}
              onChange={(event) => {
                updateData(event.target.value, 'type');
              }}
              isRequired={true}
            />
          )}
        </DialogContent>

        <DialogActions>
          <ButtonGroup
            disableElevation
            orientation="horizontal"
            fullWidth
            m={0.5}>
            <Button
              variant="outlined"
              onClick={createEntry}
              disabled={!formValid}>
              <CheckCircleIcon />
            </Button>

            <Button variant="outlined" color="error" onClick={cancelEntry}>
              <CancelIcon />
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AddInstitution;
