import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Tooltip,
} from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  AutocompleteField,
  FixedListField,
} from 'components/Main/Content/Fields';
import { AWARDS, COURSES } from 'constants/endpoints';
import { COURSE_ROLES, ROLE_FIELD } from 'constants/fields';
import { assignQuery, cancelAssignEntry, loadAddEntry } from 'store/actions';
import {
  selectAssignEntry,
  selectCurrentSection,
  selectCurrentSectionEndpoint,
  selectCurrentSectionName,
  selectFirstPosition,
  selectYear,
} from 'store/selectors';

import { buttonStyle, spanStyle } from './AssignCard.style';

const AssignCard = () => {
  const dispatch = useDispatch();

  const assignable_items = useSelector(selectAssignEntry);
  const section_name = useSelector(selectCurrentSectionName);
  const section_endpoint = useSelector(selectCurrentSectionEndpoint);
  const section = useSelector(selectCurrentSection);
  const position = useSelector(selectFirstPosition);
  const year = useSelector(selectYear);

  const [data, setData] = React.useState(0);
  const [role, setRole] = React.useState(COURSE_ROLES[0]);
  const [formValid, setFormValid] = React.useState(false);

  React.useEffect(() => {
    setFormValid(data !== 0 && COURSE_ROLES.includes(role));
  }, [data, role]);

  const assignEntry = () => {
    dispatch(assignQuery(section_endpoint, data, position.id, year, role));
  };

  const cancelEntry = () => {
    dispatch(cancelAssignEntry());
  };

  const createNewEntry = () => {
    dispatch(cancelAssignEntry());
    dispatch(loadAddEntry(section_endpoint));
  };

  const formatOption = (option) => {
    switch (section_endpoint) {
      case AWARDS:
        return `${option.name} (${option.year} ${option.event_name})`;
      case COURSES:
        return `${option.name} (${option.program})`;
      default:
        return option.name;
    }
  };

  const updateData = (_, value) => {
    setData(value ? value.id : 0);
  };

  return (
    <Card variant="outlined" square mt={1}>
      <CardContent>
        <AutocompleteField
          field="assign"
          label={section_name}
          options={assignable_items}
          formatOption={formatOption}
          onChange={updateData}
          autoFocus={true}
        />

        {section_endpoint === COURSES && (
          <FixedListField
            field={ROLE_FIELD}
            isRequired={section.mandatory_fields.includes(ROLE_FIELD)}
            options={section.role_types}
            onChange={(event) => {
              setRole(event.target.value);
            }}
          />
        )}
      </CardContent>

      <CardActions>
        <ButtonGroup orientation="horizontal" disableElevation fullWidth>
          <Tooltip title="Accept and send new entry">
            <span style={spanStyle}>
              <Button
                variant="outlined"
                fullWidth
                onClick={assignEntry}
                disabled={!formValid}
                sx={buttonStyle}>
                <CheckCircleIcon />
              </Button>
            </span>
          </Tooltip>

          <Tooltip title="Cancel">
            <Button
              variant="outlined"
              color="error"
              size="large"
              fullWidth
              onClick={cancelEntry}>
              <CancelIcon />
            </Button>
          </Tooltip>

          <Button variant="outlined" fullWidth onClick={createNewEntry}>
            Create new {section_name.toLowerCase()}
          </Button>
        </ButtonGroup>
      </CardActions>
    </Card>
  );
};

export default AssignCard;
