import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Login, Main } from 'components';
import { LOGIN_PATH, MAIN_PATH } from 'config/config';
import { AuthGuard, GuestGuard } from 'guards';

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path={LOGIN_PATH}
        element={
          <GuestGuard>
            <Login />
          </GuestGuard>
        }
      />
      <Route
        path={MAIN_PATH}
        element={
          <AuthGuard>
            <Main />
          </AuthGuard>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
