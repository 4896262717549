import AddIcon from '@mui/icons-material/Add';
import {
  AppBar,
  Box,
  Button,
  FormControlLabel,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ACADEMIC, ASSIGN_SECTIONS, SUPERVISIONS } from 'constants/endpoints';
import {
  getInstitutions,
  loadAddEntry,
  loadAssignEntry,
  setMessages,
  unsetMessages,
} from 'store/actions';
import {
  selectCurrentSection,
  selectMessagesFlag,
  selectYear,
} from 'store/selectors';

import { appBarStyle, toolbarStyle } from './TopBar.style';

const TopBar = () => {
  const dispatch = useDispatch();

  const curr_section = useSelector(selectCurrentSection);
  const year = useSelector(selectYear);
  const message_flag = useSelector(selectMessagesFlag);

  const handleClick = (event) => {
    const position = event.target.checked;
    if (position) {
      dispatch(setMessages());
    } else {
      dispatch(unsetMessages());
    }
  };

  const loadEntryForm = () => {
    if (ASSIGN_SECTIONS.includes(curr_section.endpoint)) {
      dispatch(loadAssignEntry(curr_section.endpoint, year));
    } else {
      if (curr_section.endpoint === SUPERVISIONS) {
        dispatch(getInstitutions());
      }
      dispatch(loadAddEntry(curr_section.endpoint));
    }
  };

  return (
    <AppBar position="static" color="default" elevation={0} sx={appBarStyle}>
      <Toolbar sx={toolbarStyle}>
        <Typography variant="h5" flexGrow={1}>
          {curr_section.name} in {year}
        </Typography>

        <Box m={1}>
          {curr_section.endpoint !== ACADEMIC && (
            <Tooltip title="Add new entry">
              <Button variant="contained" mr={1} onClick={loadEntryForm}>
                <AddIcon />
              </Button>
            </Tooltip>
          )}

          <FormControlLabel
            label="Help"
            labelPlacement="start"
            control={
              <Tooltip title="Display/hide help information">
                <Switch
                  checked={message_flag}
                  onChange={handleClick}
                  name="help"
                />
              </Tooltip>
            }
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
