const appBarStyle = { borderBottom: '1px solid rgba(0, 0, 0, 0.12)' };
const boxStyle = { margin: 'auto', overflow: 'hidden' };
const typoStyle = { flexGrow: 1, ml: 1 };
const buttonStyle = { maxWidth: '200px' };
const elemStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  mb: 1,
};

const columnStyle = {
  display: 'flex',
  flexDirection: 'column',
  spacing: 1,
  alignItems: 'center',
  justifyContent: 'center',
};

export {
  appBarStyle,
  boxStyle,
  buttonStyle,
  columnStyle,
  elemStyle,
  typoStyle,
};
