import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { MAIN_PATH } from 'config';
import { selectIsAuthenticated } from 'store/selectors';

const GuestGuard = ({ children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  if (isAuthenticated) {
    return <Navigate to={MAIN_PATH} />;
  }

  return children;
};

export default GuestGuard;
