import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoginIcon from '@mui/icons-material/Login';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';

import Loading from 'components/Loading/Loading';
import { API_SERVER_URL } from 'config';
import { LOGIN } from 'constants/endpoints';
import { APP_TITLE } from 'constants/messages';
import { createRequestUrl } from 'services/utils';
import {
  selectErrorMsg,
  selectErrorStatus,
  selectLoading,
} from 'store/selectors/';
import { baselineTheme } from 'styles';

import {
  avatarStyle,
  elementStyle,
  loginBoxStyle,
  loginIconStyle,
} from './Login.style';

const Login = () => {
  const errorStatus = useSelector(selectErrorStatus);
  const errorMsg = useSelector(selectErrorMsg);
  const loading = useSelector(selectLoading);

  const redirect = () => {
    window.open(createRequestUrl(API_SERVER_URL, LOGIN), '_self');
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={baselineTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={loginBoxStyle}>
          <Avatar sx={avatarStyle}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography variant="h5" sx={elementStyle}>
            {APP_TITLE}
          </Typography>

          {errorStatus && <Alert severity="error">{errorMsg}</Alert>}

          <Box sx={elementStyle}>
            <Button
              type="submit"
              variant="outlined"
              fullWidth
              onClick={redirect}>
              Log in with IMDEA <LoginIcon sx={loginIconStyle} />
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
