const drawerWidth = 256;

const contentBoxStyle = { flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' };
const mainBoxStyle = { display: 'flex', minHeight: '100vh', maxWidth: '100vw' };
const navigatorBoxStyle = { width: { sm: drawerWidth }, flexShrink: { sm: 0 } };
const restBoxStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflowX: 'hidden',
};

export {
  contentBoxStyle,
  drawerWidth,
  mainBoxStyle,
  navigatorBoxStyle,
  restBoxStyle,
};
