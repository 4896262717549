import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventIcon from '@mui/icons-material/Event';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
} from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FixedListField } from 'components/Main/Content/Fields';
import { EVENTS } from 'constants/endpoints';
import { createStaticQuery } from 'store/actions';
import { selectSections } from 'store/selectors';

const AddEvent = () => {
  const dispatch = useDispatch();

  const sections = useSelector(selectSections);
  const event_types =
    (sections.find((section) => section.endpoint === EVENTS) || {}).types || [];

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [formValid, setFormValid] = React.useState(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
    updateData(event_types[0], 'type');
  };

  const checkForm = () => {
    if (formData.name) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  const updateData = (value, key) => {
    let update = formData;
    update[key] = value;

    setFormData(update);
    checkForm();
  };

  const cancelEntry = () => {
    setDialogOpen(false);
    setFormData({});
    setFormValid(false);
  };

  const createEntry = () => {
    dispatch(createStaticQuery(EVENTS, formData));
  };

  return (
    <React.Fragment>
      <Tooltip title="Add new Event">
        <Button variant="outlined" fullWidth onClick={handleClickOpen}>
          <EventIcon />
        </Button>
      </Tooltip>

      <Dialog
        open={dialogOpen}
        onClose={cancelEntry}
        aria-labelledby="add-new-event">
        <DialogTitle id="add-new-event">Add New Event</DialogTitle>

        <DialogContent>
          <DialogContentText>
            To add a new event please specify the following fields. Before doing
            so, make sure the event you are trying to add doesn't already exist.
          </DialogContentText>

          <TextField
            autoFocus
            required
            onChange={(event) => {
              updateData(event.target.value, 'name');
            }}
            margin="dense"
            id="name"
            label="Name"
            fullWidth
          />

          <TextField
            onChange={(event) => {
              updateData(event.target.value, 'acronym');
            }}
            margin="dense"
            id="acronym"
            label="Acronym"
            fullWidth
          />

          {event_types && (
            <FixedListField
              field="type"
              options={event_types}
              onChange={(event) => {
                updateData(event.target.value, 'type');
              }}
              isReuired={true}
            />
          )}
        </DialogContent>
        <DialogActions>
          <ButtonGroup
            disableElevation
            orientation="horizontal"
            fullWidth
            m={0.5}>
            <Button
              variant="outlined"
              onClick={createEntry}
              disabled={!formValid}>
              <CheckCircleIcon />
            </Button>
            <Button variant="outlined" color="error" onClick={cancelEntry}>
              <CancelIcon />
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AddEvent;
