import HomeIcon from '@mui/icons-material/Home';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ACADEMIC } from 'constants/endpoints';
import {
  cancelAssignEntry,
  cancelNewEntry,
  getInstitutions,
  getPositions,
  getSections,
  refreshData,
  setDefaultSection,
  setSection,
  toggleMobile,
  unsetLoading,
} from 'store/actions';
import {
  selectFirstPosition,
  selectMobileFlag,
  selectSections,
  selectYear,
} from 'store/selectors';
import { muiTheme } from 'styles';

import {
  boxStyle,
  buttonStyle,
  itemStyle,
  listStyle,
  navigatorStyle,
  paperPropsStyle,
  titleStyle,
} from './Navigator.style';

const Navigator = () => {
  const dispatch = useDispatch();

  const sections = useSelector(selectSections);
  const year = useSelector(selectYear);
  const position = useSelector(selectFirstPosition);
  const mobileFlag = useSelector(selectMobileFlag);

  const isSmUp = useMediaQuery(muiTheme.breakpoints.up('sm'));

  React.useEffect(() => {
    dispatch(getSections());
    dispatch(getPositions());
  }, [dispatch]);

  const goToDefaultSection = () => {
    dispatch(setDefaultSection());
    dispatch(unsetLoading());
  };

  const goToSection = (section) => {
    dispatch(refreshData(section.endpoint, position.id, year));
    dispatch(cancelNewEntry());
    dispatch(cancelAssignEntry());
    dispatch(setSection(section));

    if (section.endpoint === ACADEMIC) {
      dispatch(getInstitutions());
    }
  };

  const toggleDrawer = () => {
    dispatch(toggleMobile());
  };

  return (
    <Drawer
      variant={isSmUp ? 'permanent' : 'temporary'}
      sx={isSmUp ? navigatorStyle : {}}
      PaperProps={paperPropsStyle}
      open={!isSmUp ? mobileFlag : undefined}
      onClose={!isSmUp ? toggleDrawer : undefined}>
      <List disablePadding sx={listStyle}>
        <ListItem sx={titleStyle}>Annual Report</ListItem>

        <Box key={'home'} sx={boxStyle}>
          <ListItemButton sx={buttonStyle} onClick={goToDefaultSection}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>General Guidelines</ListItemText>
          </ListItemButton>
        </Box>

        {sections.map(
          (section, i) =>
            section.section && (
              <Box key={i} sx={boxStyle}>
                <ListItem divider>
                  <ListItemButton
                    sx={itemStyle}
                    onClick={() => {
                      goToSection(section);
                    }}>
                    <ListItemText>{section.name}</ListItemText>
                  </ListItemButton>
                </ListItem>
              </Box>
            ),
        )}
      </List>
    </Drawer>
  );
};

export default Navigator;
