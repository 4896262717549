import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
  Alert,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
} from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  AutocompleteField,
  FixedListField,
} from 'components/Main/Content/Fields';
import { SUPERVISIONS } from 'constants/endpoints';
import { STUDENT_ROLES } from 'constants/fields';
import { createStaticQuery } from 'store/actions';
import { selectInstitutions } from 'store/selectors';
import { validEmail } from 'utils';

const AddStudent = () => {
  const dispatch = useDispatch();

  const student_roles = STUDENT_ROLES;
  const institutions = useSelector(selectInstitutions);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [formValid, setFormValid] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
    updateData(student_roles[0], 'role');
  };

  const checkForm = () => {
    if (
      formData.name &&
      formData.surname_1 &&
      validEmail(formData.email) &&
      student_roles.includes(formData.role) &&
      institutions.find((o) => o.id === formData.institution_id)
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  const updateData = (value, key) => {
    let update = formData;
    update[key] = value;

    setFormData(update);
    checkForm();
  };

  const cancelEntry = () => {
    setDialogOpen(false);
    setFormData({});
    setFormValid(false);
  };

  const createEntry = () => {
    dispatch(createStaticQuery(SUPERVISIONS, formData));
  };

  return (
    <React.Fragment>
      <Tooltip title="Add new Student">
        <Button variant="outlined" fullWidth onClick={handleClickOpen}>
          <PersonAddIcon />
        </Button>
      </Tooltip>

      <Dialog
        open={dialogOpen}
        onClose={cancelEntry}
        aria-labelledby="add-new-student">
        <DialogTitle id="add-new-student">Add New Student</DialogTitle>

        <DialogContent>
          <DialogContentText>
            To add a new student please specify the following fields. Before
            doing so, make sure the student you are trying to add doesn't
            already exist.
          </DialogContentText>

          <TextField
            autoFocus
            required
            onChange={(event) => {
              updateData(event.target.value, 'name');
            }}
            margin="dense"
            id="name"
            label="Name"
            fullWidth
          />

          <TextField
            required
            onChange={(event) => {
              updateData(event.target.value, 'surname_1');
            }}
            margin="dense"
            id="surname_1"
            label="Surname_1"
            fullWidth
          />

          <TextField
            onChange={(event) => {
              updateData(event.target.value, 'surname_2');
            }}
            margin="dense"
            id="surname_2"
            label="Surname_2"
            fullWidth
          />

          <TextField
            required
            onChange={(event) => {
              updateData(event.target.value, 'email');
              setError(!validEmail(event.target.value));
            }}
            margin="dense"
            id="email"
            label="Email"
            fullWidth
          />

          {error && <Alert severity="error">Wrong email</Alert>}

          {student_roles && (
            <FixedListField
              field="role"
              options={student_roles}
              onChange={(event) => {
                updateData(event.target.value, 'role');
              }}
              isRequired={true}
            />
          )}

          {institutions && (
            <AutocompleteField
              field="institution"
              label="Institution"
              options={institutions}
              formatOption={(option) => option.name}
              onChange={(_, value) =>
                updateData(value ? value.id : 0, 'institution_id')
              }
              getOptionLabel={(institution) =>
                `${institution.name} (${institution.country})`
              }
            />
          )}
        </DialogContent>

        <DialogActions>
          <ButtonGroup
            disableElevation
            orientation="horizontal"
            fullWidth
            m={0.5}>
            <Button
              variant="outlined"
              onClick={createEntry}
              disabled={!formValid}>
              <CheckCircleIcon />
            </Button>

            <Button variant="outlined" color="error" onClick={cancelEntry}>
              <CancelIcon />
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AddStudent;
