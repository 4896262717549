const defaultOptions = (type) => {
  return {
    mode: 'cors',
    method: type,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  };
};

const createRequestUrl = (baseUrl, path, params = {}) => {
  const url = new URL(baseUrl);

  url.pathname = `${url.pathname.replace(/\/$/, '')}/${path.replace(/^\//, '')}`;

  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key]),
  );

  return url.toString();
};

const getResponseErrorMsg = async (response) => {
  const error = await response.json();
  return error.message;
};

const sendRequest = async (request) => {
  const response = await fetch(request);
  if (!response.ok) {
    const error_message = await getResponseErrorMsg(response);
    throw new Error(error_message);
  }
  return await response.json();
};

const isPayloadEmpty = (payload) => {
  return Object.entries(payload).length === 0;
};

const transformPayload = (payload) => {
  let formData = new FormData();
  Object.keys(payload).map((key, index) => {
    formData.append(key, payload[key]);
    return index;
  });
  return formData;
};

export {
  createRequestUrl,
  defaultOptions,
  getResponseErrorMsg,
  isPayloadEmpty,
  sendRequest,
  transformPayload,
};
