import {
  ACADEMIC,
  AWARDS,
  COURSES,
  INSTITUTIONS,
  POSITIONS,
  PROTOTYPES,
} from 'constants/endpoints';
import { AUTH_ERROR_MSG } from 'constants/messages';
import { createData, deleteData, getData, modifyData } from 'services';
import {
  CANCEL_ASSIGN_ENTRY,
  CANCEL_NEW_ENTRY,
  GET_ASSIGN_ENTRY,
  GET_DATA,
  GET_INSTITUTIONS,
  GET_POSITIONS,
  LOGOUT,
  SET_ASSIGN_ENTRY,
  SET_LOADING,
  SET_NEW_ENTRY,
  UNSET_LOADING,
} from 'store/actions/types';
import {
  genericAction,
  getAddEntryArgs,
  getStaticQueryArgs,
} from 'store/actions/utils';

const getPositions = () => {
  const path = `${POSITIONS}/get`;
  return getQuery(path, GET_POSITIONS);
};

const getInstitutions = () => {
  const path = `${INSTITUTIONS}/get`;
  return getQuery(path, GET_INSTITUTIONS);
};

const refreshData = (endpoint, position_id, year) => {
  const path = `${endpoint}/get/${position_id}${endpoint === ACADEMIC ? '' : `/${year}`}`;

  return getQuery(path, GET_DATA);
};

const getQuery = (endpoint, action) => {
  return async (dispatch) => {
    dispatch(genericAction(SET_LOADING));
    try {
      const body = await getData(endpoint);
      dispatch(genericAction(action, { data: body }));
    } catch (error) {
      if (AUTH_ERROR_MSG.includes(error.message)) {
        dispatch(genericAction(LOGOUT));
      }
    } finally {
      dispatch(genericAction(UNSET_LOADING));
    }
  };
};

const deleteQuery = (endpoint, id, position_id, year) => {
  const path = `${endpoint}/remove/${id}`;
  return async (dispatch) => {
    dispatch(genericAction(SET_LOADING));
    try {
      await deleteData(path);
    } catch (error) {
      console.log('Error:', error);
    } finally {
      dispatch(refreshData(endpoint, position_id, year));
      dispatch(genericAction(UNSET_LOADING));
    }
  };
};

const deleteSupervisionQuery = (endpoint, student_id, position_id, year) => {
  const path = `${endpoint}/remove`;
  const payload = {
    supervisor_id: position_id,
    student_id: student_id,
    year: year,
  };
  return async (dispatch) => {
    dispatch(genericAction(SET_LOADING));
    try {
      await deleteData(path, payload);
    } catch (error) {
      console.log('Error:', error);
    } finally {
      dispatch(refreshData(endpoint, position_id, year));
      dispatch(genericAction(UNSET_LOADING));
    }
  };
};

const createQuery = (endpoint, position_id, year, payload) => {
  const path = `${endpoint}/add`;
  return async (dispatch) => {
    dispatch(genericAction(SET_LOADING));
    try {
      await createData(path, payload);
      dispatch(genericAction(CANCEL_NEW_ENTRY));
    } catch (error) {
      console.log('Error:', error);
    } finally {
      dispatch(refreshData(endpoint, position_id, year));
      dispatch(genericAction(UNSET_LOADING));
    }
  };
};

const createStaticQuery = (endpoint, payload) => {
  const { path, refresh_path, action } = getStaticQueryArgs(endpoint);
  return async (dispatch) => {
    dispatch(genericAction(SET_LOADING));
    try {
      await createData(path, payload);
      dispatch(genericAction(CANCEL_NEW_ENTRY));
    } catch (error) {
      console.log('Error:', error);
    } finally {
      dispatch(getQuery(refresh_path, action));
      dispatch(genericAction(UNSET_LOADING));
    }
  };
};

const loadAddEntry = (endpoint) => {
  const { path, action } = getAddEntryArgs(endpoint);

  return async (dispatch) => {
    dispatch(genericAction(SET_LOADING));
    try {
      if (path) {
        dispatch(getQuery(path, action));
      }
      dispatch(genericAction(SET_NEW_ENTRY));
    } catch (error) {
      console.log('Error:', error);
    } finally {
      dispatch(genericAction(UNSET_LOADING));
    }
  };
};

const loadAssignEntry = (endpoint, year) => {
  const path = `${endpoint}/get${endpoint === AWARDS ? `/${year}` : ''}`;

  return async (dispatch) => {
    dispatch(genericAction(SET_LOADING));
    try {
      dispatch(getQuery(path, GET_ASSIGN_ENTRY));
      dispatch(genericAction(SET_ASSIGN_ENTRY));
    } catch (error) {
      console.log('Error:', error);
    } finally {
      dispatch(genericAction(UNSET_LOADING));
    }
  };
};

const assignQuery = (endpoint, id, position_id, year, role = null) => {
  const path = `${endpoint}/assign/${id}`;
  let payload = {
    position_id: position_id,
  };
  if ([COURSES, PROTOTYPES].includes(endpoint)) {
    payload.year = year;
  }
  if ([COURSES].includes(endpoint)) {
    payload.role = role;
  }
  return async (dispatch) => {
    dispatch(genericAction(SET_LOADING));
    try {
      await createData(path, payload);
      dispatch(genericAction(CANCEL_ASSIGN_ENTRY));
    } catch (error) {
      console.log('Error:', error);
    } finally {
      dispatch(refreshData(endpoint, position_id, year));
      dispatch(genericAction(UNSET_LOADING));
    }
  };
};

const revokeQuery = (endpoint, id, payload) => {
  const path = `${endpoint}/revoke/${id}`;

  return async (dispatch) => {
    dispatch(genericAction(SET_LOADING));
    try {
      await createData(path, payload);
    } catch (error) {
      console.log('Error:', error);
    } finally {
      dispatch(refreshData(endpoint, payload.position_id, payload.year));
      dispatch(genericAction(UNSET_LOADING));
    }
  };
};

const modifyQuery = (endpoint, id, year, payload) => {
  const path = `${endpoint}/modify/${id}`;
  return async (dispatch) => {
    dispatch(genericAction(SET_LOADING));
    try {
      await modifyData(path, payload);
    } catch (error) {
      console.log('Error:', error);
    } finally {
      dispatch(refreshData(endpoint, id, year));
      dispatch(genericAction(UNSET_LOADING));
    }
  };
};

export {
  assignQuery,
  createQuery,
  createStaticQuery,
  deleteQuery,
  deleteSupervisionQuery,
  getInstitutions,
  getPositions,
  getQuery,
  loadAddEntry,
  loadAssignEntry,
  modifyQuery,
  refreshData,
  revokeQuery,
};
