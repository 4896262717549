import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';

import { Content } from 'components/Main/Content';
import { Header } from 'components/Main/Header';
import { Navigator } from 'components/Main/Navigator';
import { muiTheme } from 'styles';

import {
  contentBoxStyle,
  mainBoxStyle,
  navigatorBoxStyle,
  restBoxStyle,
} from './Main.style';

const Main = () => {
  return (
    <ThemeProvider theme={muiTheme}>
      <Box sx={mainBoxStyle}>
        <CssBaseline />
        <Box component="nav" sx={navigatorBoxStyle}>
          <Navigator />
        </Box>

        <Box sx={restBoxStyle}>
          <Header />

          <Box component="main" sx={contentBoxStyle}>
            <Content />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Main;
