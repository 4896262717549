import CircularProgress from '@mui/material/CircularProgress';
import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { AppRoutes } from 'routes';

const App = () => {
  return (
    <Router>
      <Suspense fallback={<CircularProgress />}>
        <AppRoutes />
      </Suspense>
    </Router>
  );
};

export default App;
