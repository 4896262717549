import { TextField } from '@mui/material';

const FixedListField = ({
  field,
  options,
  onChange,
  isRequired = false,
  autoFocus = false,
}) => (
  <TextField
    id={field}
    label={`${field[0].toUpperCase()}${field.slice(1)}`}
    margin="dense"
    select
    multiline
    fullWidth
    maxRows={Infinity}
    SelectProps={{
      native: true,
    }}
    required={isRequired}
    autoFocus={autoFocus}
    onChange={onChange}>
    {options.map((option, index) => (
      <option key={index} value={option}>
        {option}
      </option>
    ))}
  </TextField>
);

export default FixedListField;
