import { Box, CircularProgress, Container, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';

import { baselineTheme } from 'styles';

import { loadingBoxStyle } from './Loading.style';

const Loading = () => {
  return (
    <ThemeProvider theme={baselineTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={loadingBoxStyle}>
          <CircularProgress />
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Loading;
