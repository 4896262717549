import React from 'react';
import { Navigate } from 'react-router-dom';

import { LOGIN_PATH } from 'config';
import { useAuth } from 'hooks';

const AuthGuard = ({ children }) => {
  const isAuthenticated = useAuth();

  if (!isAuthenticated) {
    return <Navigate to={LOGIN_PATH} />;
  }

  return children;
};

export default AuthGuard;
