import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Grid,
  Tooltip,
} from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddEvent from 'components/Main/Content/NewCard/AddEntry/AddEvent';
import AddInstitution from 'components/Main/Content/NewCard/AddEntry/AddInstitution';
import AddStudent from 'components/Main/Content/NewCard/AddEntry/AddStudent';
import { renderFields } from 'components/Main/Content/NewCard/renderFields';
import {
  ACADEMIC,
  AWARDS,
  CONFERENCE_SERVICES,
  COURSES,
  EVENT_SECTIONS,
  INDUSTRY_COLLABS,
  INSTITUTION_SECTIONS,
  OTHER_SERVICES,
  OTHER_TALKS,
  PAPERS,
  PROTOTYPES,
  SUPERVISIONS,
  TALKS,
  VISITS,
} from 'constants/endpoints';
import {
  EXCLUDED_FIELDS,
  LEVEL_FIELD,
  POSITION_ID_FIELD,
  ROLE_FIELD,
  STATUS_FIELD,
  TYPE_FIELD,
  YEAR_FIELD,
} from 'constants/fields';
import { cancelNewEntry, createQuery } from 'store/actions';
import {
  selectCurrentSection,
  selectEvents,
  selectFirstPosition,
  selectInstitutions,
  selectSupervisions,
  selectYear,
} from 'store/selectors';
import {
  validAcademic,
  validAward,
  validConferenceService,
  validCourse,
  validIndustryCollab,
  validOtherService,
  validOtherTalk,
  validPaper,
  validPrototype,
  validSupervision,
  validTalk,
  validVisit,
} from 'utils';

import { buttonStyle, spanStyle } from './NewCard.style';

const NewCard = () => {
  const dispatch = useDispatch();

  const position = useSelector(selectFirstPosition);
  const year = useSelector(selectYear);
  const currsection = useSelector(selectCurrentSection);
  const events = useSelector(selectEvents);
  const institutions = useSelector(selectInstitutions);
  const students = useSelector(selectSupervisions);

  const [data, setData] = React.useState({});
  const [requiredFlag, setRequiredFlag] = React.useState(true);
  const [workloadLabel, setWorkloadLabel] = React.useState('Workload (ECTS)');
  const [formValid, setFormValid] = React.useState(false);

  const updateData = (value, key) => {
    let update = data;
    let newRequiredFlag = requiredFlag;
    let newWorkloadLabel = workloadLabel;
    update[key] = value;

    if (currsection.endpoint === PAPERS) {
      newRequiredFlag = data.status === currsection.types[0];
    }

    if (currsection.endpoint === COURSES) {
      let course_types = [currsection.types[0], currsection.types[1]];
      newWorkloadLabel = course_types.includes(data.level)
        ? 'Workload (ECTS)'
        : 'Workload (hours)';
    }

    if (currsection.fields.includes(YEAR_FIELD)) {
      update[YEAR_FIELD] = year;
    }

    if (currsection.fields.includes(POSITION_ID_FIELD)) {
      update[POSITION_ID_FIELD] = position.id;
    }

    if (
      currsection.fields.includes(TYPE_FIELD) &&
      !update.hasOwnProperty(TYPE_FIELD) &&
      currsection.endpoint !== INDUSTRY_COLLABS
    ) {
      update[TYPE_FIELD] = currsection.types[0];
    }

    if (
      currsection.fields.includes(ROLE_FIELD) &&
      !update.hasOwnProperty(ROLE_FIELD)
    ) {
      update[ROLE_FIELD] = currsection.role_types[0];
    }

    if (
      currsection.fields.includes(LEVEL_FIELD) &&
      !update.hasOwnProperty(LEVEL_FIELD)
    ) {
      update[LEVEL_FIELD] = currsection.types[0];
    }

    if (
      currsection.fields.includes(STATUS_FIELD) &&
      !update.hasOwnProperty(STATUS_FIELD)
    ) {
      update[STATUS_FIELD] = currsection.types[0];
    }

    setRequiredFlag(newRequiredFlag);
    setWorkloadLabel(newWorkloadLabel);
    setData(update);

    checkForm();
  };

  const checkForm = () => {
    const { endpoint, types } = currsection;

    const validators = {
      [AWARDS]: () => validAward(data, events, types),
      [INDUSTRY_COLLABS]: () => validIndustryCollab(data, institutions),
      [PROTOTYPES]: () => validPrototype(data),
      [CONFERENCE_SERVICES]: () => validConferenceService(data, events, types),
      [OTHER_SERVICES]: () => validOtherService(data, types),
      [SUPERVISIONS]: () => validSupervision(data, students),
      [OTHER_TALKS]: () => validOtherTalk(data, institutions),
      [TALKS]: () => validTalk(data, events, types),
      [COURSES]: () =>
        validCourse(data, institutions, types, currsection.role_types),
      [VISITS]: () => validVisit(data, institutions),
      [PAPERS]: () => validPaper(data, events, types),
      [ACADEMIC]: () => validAcademic(data),
    };

    const validator = validators[endpoint];

    setFormValid(validator ? validator() : false);
  };

  const cancelEntry = () => {
    dispatch(cancelNewEntry());
  };

  const createEntry = () => {
    dispatch(createQuery(currsection.endpoint, position.id, year, data));
  };

  return (
    <Card variant="outlined" square mt={1}>
      <CardContent>
        <Grid
          container
          spacing={1}
          justifyContent="space-around"
          alignItems="center">
          {currsection.fields
            .filter((field) => !EXCLUDED_FIELDS.includes(field))
            .map((field, i) => (
              <Grid item xs={6} key={i}>
                {renderFields(
                  field,
                  currsection,
                  events,
                  students,
                  institutions,
                  updateData,
                  {},
                  workloadLabel,
                  requiredFlag,
                )}
              </Grid>
            ))}
        </Grid>
      </CardContent>

      <CardActions>
        <ButtonGroup
          disableElevation
          orientation="horizontal"
          fullWidth
          m={0.5}>
          <Tooltip title="Accept and send new entry">
            <span style={spanStyle}>
              <Button
                variant="outlined"
                onClick={createEntry}
                disabled={!formValid}
                sx={buttonStyle}>
                <CheckCircleIcon />
              </Button>
            </span>
          </Tooltip>

          <Tooltip title="Cancel">
            <Button variant="outlined" color="error" onClick={cancelEntry}>
              <CancelIcon />
            </Button>
          </Tooltip>

          {currsection.endpoint === SUPERVISIONS && <AddStudent />}

          {INSTITUTION_SECTIONS.includes(currsection.endpoint) && (
            <AddInstitution />
          )}

          {EVENT_SECTIONS.includes(currsection.endpoint) && <AddEvent />}
        </ButtonGroup>
      </CardActions>
    </Card>
  );
};

export default NewCard;
