import { logout as logoutUser } from 'services';
import { fetchAuthStatus } from 'services/userService';
import {
  LOGIN,
  LOGOUT,
  SET_ERROR,
  SET_LOADING,
  UNSET_LOADING,
} from 'store/actions/types';
import { genericAction } from 'store/actions/utils';

const updateAuthStatus = () => {
  return async (dispatch) => {
    dispatch(genericAction(SET_LOADING));
    try {
      const body = await fetchAuthStatus();
      dispatch(genericAction(LOGIN, { username: body.username }));
      sessionStorage.setItem('username', body.username);
    } catch (error) {
      dispatch(genericAction(SET_ERROR, error.message));
    } finally {
      dispatch(genericAction(UNSET_LOADING));
    }
  };
};

const logout = () => {
  return async (dispatch) => {
    dispatch(genericAction(LOGOUT));
    sessionStorage.clear();
    try {
      await logoutUser();
    } catch (error) {
      console.log('Error:', error);
    }
  };
};

export { logout, updateAuthStatus };
