import { TextField } from '@mui/material';

import {
  AutocompleteField,
  DateField,
  FixedListField,
} from 'components/Main/Content/Fields';
import { INDUSTRY_COLLABS } from 'constants/endpoints';
import {
  DATE_FIELDS,
  EVENT_ID_FIELD,
  INSTITUTION_ID_FIELD,
  ROLE_FIELD,
  STUDENT_ID_FIELD,
  TYPE_FIELDS,
  WORKLOAD_FIELD,
} from 'constants/fields';

const renderFields = (
  field,
  section,
  events,
  students,
  institutions,
  updateData,
  data = {},
  customLabel = null,
  requiredFlag = null,
) => {
  const commonArgs = {
    field,
    isRequired: section.mandatory_fields.includes(field),
  };

  const hasCustomValue = section.name === 'Academic Info';

  if (
    (TYPE_FIELDS.includes(field) || field === ROLE_FIELD) &&
    section.endpoint !== INDUSTRY_COLLABS
  ) {
    const options = field === ROLE_FIELD ? section.role_types : section.types;
    return (
      <FixedListField
        {...commonArgs}
        options={options}
        onChange={(event) => {
          updateData(event.target.value, field);
        }}
      />
    );
  } else if (field === EVENT_ID_FIELD) {
    return (
      <AutocompleteField
        {...commonArgs}
        label="Events"
        options={events}
        formatOption={(option) =>
          `${option.name}${option.acronym ? ` (${option.acronym})` : ''}`
        }
        getOptionLabel={(event) =>
          event.acronym ? `${event.name} (${event.acronym})` : event.name
        }
        onChange={(_, value) => {
          updateData(value ? value.id : 0, field);
        }}
      />
    );
  } else if (field === INSTITUTION_ID_FIELD) {
    const institutionById = (id) =>
      institutions.find((institution) => institution.id === id);
    const value = data[field] ? institutionById(data[field]) : null;
    return (
      <AutocompleteField
        {...commonArgs}
        {...(hasCustomValue && { value: value })}
        label="Institution"
        options={institutions}
        formatOption={(option) => option.name}
        onChange={(_, value) => updateData(value ? value.id : null, field)}
      />
    );
  } else if (field === STUDENT_ID_FIELD) {
    return (
      <AutocompleteField
        {...commonArgs}
        label="Students"
        options={students}
        formatOption={(option) => `${option.name} ${option.surname_1}`}
        onChange={(_, value) =>
          updateData(value ? value.position_id : 0, field)
        }
        getOptionLabel={(student) => `${student.name} ${student.surname_1}`}
      />
    );
  } else if (DATE_FIELDS.includes(field)) {
    return (
      <DateField
        {...commonArgs}
        {...(hasCustomValue && { value: data[field] })}
        onChange={(date) => {
          updateData(date, field);
        }}
      />
    );
  } else if (field === WORKLOAD_FIELD) {
    return (
      <TextField
        id={field}
        label={customLabel}
        onChange={(event) => {
          updateData(event.target.value, field);
        }}
        required={section.mandatory_fields.includes(field)}
        margin="dense"
        fullWidth
        multiline
        maxRows={Infinity}
      />
    );
  } else {
    return (
      <TextField
        id={field}
        {...(hasCustomValue && { value: data[field] })}
        label={`${field[0].toUpperCase()}${field.slice(1)}`}
        onChange={(event) => {
          updateData(event.target.value, field);
        }}
        required={section.mandatory_fields.includes(field)}
        margin="dense"
        fullWidth
        multiline
        maxRows={Infinity}
      />
    );
  }
};

export { renderFields };
