import { MAIN } from 'constants/endpoints';
import { getQuery } from 'store/actions';
import {
  CANCEL_ASSIGN_ENTRY,
  CANCEL_NEW_ENTRY,
  GET_SECTIONS,
  SET_MESSAGES,
  SET_SECTION,
  TOGGLE_MOBILE,
  UNSET_LOADING,
  UNSET_MESSAGES,
} from 'store/actions/types';
import { genericAction } from 'store/actions/utils';

const getSections = () => {
  return getQuery(MAIN, GET_SECTIONS);
};

const setDefaultSection = () => {
  return async (dispatch) => {
    dispatch(genericAction(SET_SECTION, { name: '', endpoint: '' }));
  };
};

const setSection = (section) => {
  return async (dispatch) => {
    dispatch(genericAction(SET_SECTION, section));
  };
};

const unsetLoading = () => {
  return async (dispatch) => {
    dispatch(genericAction(UNSET_LOADING));
  };
};

const cancelNewEntry = () => {
  return async (dispatch) => {
    dispatch(genericAction(CANCEL_NEW_ENTRY));
  };
};

const cancelAssignEntry = () => {
  return async (dispatch) => {
    dispatch(genericAction(CANCEL_ASSIGN_ENTRY));
  };
};

const setMessages = () => {
  return async (dispatch) => {
    dispatch(genericAction(SET_MESSAGES));
  };
};

const unsetMessages = () => {
  return async (dispatch) => {
    dispatch(genericAction(UNSET_MESSAGES));
  };
};

const toggleMobile = () => {
  return async (dispatch) => {
    dispatch(genericAction(TOGGLE_MOBILE));
  };
};

export {
  cancelAssignEntry,
  cancelNewEntry,
  getSections,
  setDefaultSection,
  setMessages,
  setSection,
  toggleMobile,
  unsetLoading,
  unsetMessages,
};
